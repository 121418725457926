import {
  PRODUCT_CHAT_SUGGESTION,
  PRODUCT_RECOMMENDED_BY_RVP,
  PRODUCT_RECOMMENDED_BY_WISHLIST,
  PRODUCT_SEARCH_GRID,
  PRODUCT_SEARCHBAR_SUGGESTION,
  PRODUCT_SECTION_CATEGORY_NEW,
  PRODUCT_SECTION_DISCOUNTED,
  PRODUCT_SECTION_NEW,
  PRODUCT_SECTION_POPULAR,
  PRODUCT_SECTION_SUGGESTED,
  PRODUCT_SHOP,
  PRODUCT_SHOP_DEALS,
  PRODUCT_SIMILAR_PRODUCTS_SECTION,
  PRODUCT_TOP_RECOMMENDED_BY_RVP,
  PRODUCT_TOP_RECOMMENDED_BY_WISHLIST,
} from './ProductCardOriginType.def';
import dataLayerPush from '../../utils/dataLayerPush';

const GTMClickEventByCardOriginType = {
  [PRODUCT_SEARCH_GRID]: 'search_results_product_clicked',
  [PRODUCT_SECTION_DISCOUNTED]: 'discounted_product_clicked',
  [PRODUCT_SECTION_NEW]: 'new_product_clicked',
  [PRODUCT_SECTION_POPULAR]: 'popular_product_clicked',
  [PRODUCT_SECTION_CATEGORY_NEW]: 'category_new_product_clicked',
  [PRODUCT_SECTION_SUGGESTED]: 'suggested_product_clicked',
  [PRODUCT_CHAT_SUGGESTION]: 'chat_suggested_product_clicked',
  [PRODUCT_SEARCHBAR_SUGGESTION]: 'searchbar_suggested_product_clicked',
  [PRODUCT_SHOP_DEALS]: 'shop_deals_product_clicked',
  [PRODUCT_SHOP]: 'shop_product_clicked',
  [PRODUCT_SIMILAR_PRODUCTS_SECTION]: 'product_page_similar_product_clicked',
  [PRODUCT_RECOMMENDED_BY_RVP]: 'recommended_by_rvp_product_clicked',
  [PRODUCT_RECOMMENDED_BY_WISHLIST]: 'recommended_by_wishlist_product_clicked',
  [PRODUCT_TOP_RECOMMENDED_BY_RVP]: 'top_recommended_by_rvp_product_clicked',
  [PRODUCT_TOP_RECOMMENDED_BY_WISHLIST]: 'top_recommended_by_wishlist_product_clicked',
};

const getDataLayerPayload = (productCardOriginType, cookies, resultRank, id, category, name, price) => {
  switch (productCardOriginType) {
    case PRODUCT_SEARCH_GRID:
      return {
        event: GTMClickEventByCardOriginType[productCardOriginType],
        query: cookies['search_query'] || '',
        filters_applied: JSON.stringify(cookies['search_filters_applied'] || {}),
        search_id: cookies['search_id'],
        result_rank: resultRank,
        product_id: id,
        product_name: name,
        category,
        price,
        currency: process.env.REACT_APP_WEBSITE_CURRENCY,
      };
    default:
      return {
        event: GTMClickEventByCardOriginType[productCardOriginType],
        result_rank: resultRank,
        product_id: id,
        product_name: name,
        category,
        price,
        currency: process.env.REACT_APP_WEBSITE_CURRENCY,
      };
  }
};

export const handleProductCardGTMClick = (productCardOriginType, cookies, resultRank, id, category, name, price) => {
  if (!GTMClickEventByCardOriginType[productCardOriginType]) return;

  dataLayerPush(getDataLayerPayload(productCardOriginType, cookies, resultRank, id, category, name, price));
};
